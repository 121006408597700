<template>
  <div>
    <div v-if="isGsgg">
      <div v-if="pageData.page && pageData.page.length > 0">
        <ul class="page-item" v-for="(item, i) in pageData.page" :key="i" >
          <el-row>
            <el-col :span="21">
              <li class="m-l-10 pointer hover" @click="checkDetail(item.id)">
                {{ item.title }}
              </li>
            </el-col>
            <el-col :span="3">
              <span class="color2C8BFF">{{ item.ctime | formatDate('yyyy-MM-dd') }}&nbsp;&nbsp;</span>
            </el-col>
          </el-row>
        </ul>
      </div>
      <div v-else class="no-data">暂无数据</div>
    </div>
    <div v-else>
      <div v-if="pageData.page && pageData.page.length > 0">
        <div class="new-item" v-for="(item, i) in pageData.page" :key="i" >
          <div class="flex flex-a_c flex-j_between pointer childWidth" @click="checkDetail(item.id)" >
            <div class="hover pl10">{{ item.mainAuthor }}</div>
            <div class="hover pr20">{{ item.title }}</div>
            <div v-if="item.startTime">
              {{ format(item.startTime) }}
            </div>
            <div v-else>
              {{ format(item.ctime) }}
            </div>
            <div v-if="item.endTime">
              {{ format(item.endTime) }}
              &nbsp;&nbsp;
              <i class="el-icon-timer" v-if="Date.now() > item.endTime">已过期</i>
            </div>
            <div v-else>
              {{ format(item.utime) }}
              &nbsp;&nbsp;
              <i class="el-icon-timer" v-if="Date.now() > item.endTime">已过期</i>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-data">暂无数据</div>
    </div>

    <div class="pagination-content" v-if="pageshow">
      <EPagination
        class="pagination"
        :total="pageData.recordCount"
        :pageSize="pageSize"
        :current="currentPage"
        @currentPageClick="currentPageClickHandle"
      />
    </div>
  </div>
</template>

<script>
import { formatDate } from '@/filters/index';
import EPagination from '@/components/e-pagination/index';
export default {
  name: 'pageList',
  components: {
    EPagination,
  },
  props: {
    pageData: {
      type: Object,
      default() {
        return {};
      },
    },
    isGsgg: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      pageshow: true,
    };
  },
  mounted(){
    console.log(this.pageData);
    console.log(this.pageData.page);
    console.log(JSON.parse(JSON.stringify(this.pageData.page)));
  },
  computed: {
    format() {
      return function (date) {
        return formatDate(date, 'yyyy-MM-dd');
      };
    },
  },
  methods: {
    setCurrentPage(value) {
      this.pageshow = false;
      this.currentPage = value + 1;
    },
    checkDetail(id) {
      this.$emit('checkDetail', id);
    },
    currentPageClickHandle(val) {
      this.$emit('pageChange', val);
    },
  },
};
</script>

<style lang="scss" scoped>
.no-data {
  text-align: center;
  padding: 10% 0;
  color: #909399;
}
.pagination-content {
  margin-top: 20px;
  text-align: center;
  padding-bottom: 12px;
}
.m-l-10 {
  margin-left: 10px;
}
.page-item {
  position: relative;
  height: 70px;
  line-height: 70px;
  border-bottom: dashed 1px #cccccc;
  &:hover {
    background-color: #9bc8ff;
    color: #fff !important;
    .color2C8BFF {
      color: #fff !important;
    }
  }
  .hover {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  span {
    &:last-child {
      float: right;
      color: grey;
    }
  }
}
.new-item {
  height: 71px;
  line-height: 70px;
  border-bottom: dashed 1px #cccccc;
  &:hover {
    background-color: #9bc8ff;
    color: #fff !important;
  }
  .hover {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .childWidth {
    div {
      width: 25%;
    }
  }
}
</style>

<template>
  <div class="content">
    <div class="feedback-fixed" @click="$router.push('/message')" v-if="$hasPermission('IEBDC:GNXS:LYB')">
      <!-- <img src="@/pages/iebdc/assets/images/feedback.png" alt="" /> -->
    </div>
    <div>
      <div class="backColor flex bread-menu-wrap">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item><span class="crumbBold">当前位置:</span>&nbsp;&nbsp;</el-breadcrumb-item>
          <el-breadcrumb-item
            ><span class="cursor crumbBold" :class="showDetail ? 'bold' : ''" type="text" @click="showDetail = false"
              >公示公告</span
            ></el-breadcrumb-item
          >
          <el-breadcrumb-item v-if="showDetail">
            <span class="crumbBold">{{ djdlArry[currentIndex].label }}</span></el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
      <div class="top-tab flex" v-if="!showDetail">
        <div
          v-for="(item, index) in djdlArry"
          @click="toggle(index)"
          :key="index"
          class="checkList"
          :class="currentIndex === index ? 'current' : ''"
        >
          {{ item.label }}
        </div>
        <div class="search">
          <el-input clearable v-model.trim="title" size="mini" placeholder="输入标题"> </el-input>
          <el-button size="mini" type="primary" @click="queryData">搜索</el-button>
        </div>
      </div>
      <div v-show="!showDetail">
        <div class="query-content" v-if="pageData.page && pageData.page.length > 0">
          <el-row>
            <el-col :span="6">
              <div class="article-title">&nbsp;&nbsp;区县名称</div>
            </el-col>
            <el-col :span="6">
              <div class="article-title">标题名称</div>
            </el-col>
            <el-col :span="6">
              <div class="article-title">公告开始日期</div>
            </el-col>
            <el-col :span="6">
              <div class="article-title">公告结束日期</div>
            </el-col>
          </el-row>
        </div>
        <div class="page-content">
          <pageList
            ref="table"
            :isGsgg="false"
            :pageData="pageData"
            @checkDetail="checkDetail"
            @pageChange="pageChange"
          />
        </div>
      </div>
      <PolicyDetail v-show="showDetail" :detailData="detailData" @back="back" />
    </div>
    <!--  后台配置权限该功能是否在建  -->
    <!-- <no-data v-else :data="dataNo"></no-data> -->
  </div>
</template>

<script>
import { getPolicyData, getPolicyDetail } from '@iebdc/api/policy-service';
import pageList from './page-list';
import PolicyDetail from '../policy-law/component/policy-detail';
import { getArea } from '@manager/api/user';
import { mapGetters, mapState } from 'vuex';
export default {
  name: 'publicAnnouncement',
  components: {
    pageList,
    PolicyDetail,
  },
  data() {
    return {
      dataNo: {
        src: require('@/pages/iebdc/assets/images/nodata@2x.png'),
        imgWidth: '198px',
        imgHeight: '154px',
        marginTop: '-108px',
        title: '敬请期待',
        description: '正在建设中...',
      },
      currentIndex: 0,
      djdlArry: [
        {
          label: '登记公告',
          value: 'DJGG',
        },
        {
          label: '遗失声明',
          value: 'YSSM',
        },
        {
          label: '继承公示',
          value: 'JCGS',
        },
        {
          label: '系统公告',
          value: 'XTGG',
        },
      ],
      pageData: {},
      showDetail: false,
      detailData: {},
      title: '',
      artitle: '',
      category: 'DJGG',
    };
  },
  computed: {
    // ...mapGetters(['selectedOrganizationsCode']),
    ...mapGetters('area', ['getAreaNameByCode']),
    ...mapState('area', ['area']),
  },
  //   watch: {
  //     selectedOrganizationsCode: function () {
  //       this.getPolicyData();
  //     },
  //   },
  mounted() {},
  methods: {
    toggle(i) {
      this.currentIndex = i;
      this.category = this.djdlArry[i].value;
      // 设置页码为1
      this.$refs.table.setCurrentPage(1);
      this.getPolicyData(0);
    },
    queryData() {
      this.$refs.table.setCurrentPage(0);
      this.query();
    },
    query() {
      this.getPolicyData();
    },
    getPolicyData(current) {
      this.pageData = {};

      getPolicyData({
        nCurrent: current,
        articleTypeEnum: 'GGGS',
        title: this.title,
        category: this.category,
        visible: true,
      }).then((res) => {
        this.$refs.table.setCurrentPage(current);
        const data = res.data;
        (data.page || []).forEach((item) => {
          if (item.djjg) {
            item.mainAuthor = this.getAreaNameByCode(item.djjg);
          }
          this.pageData = data;
          if (this.$refs.table) {
            this.$refs.table.pageshow = true;
          }
        });
      });
    },

    // 查看文章详细内容
    checkDetail(id) {
      getPolicyDetail({ id }).then((res) => {
        if (res && res.data) {
          this.detailData = res.data;
          this.showDetail = true;
        }
      });
    },
    // 分页查询
    pageChange(currentPage) {
      this.getPolicyData(currentPage - 1);
    },
    // 返回到文章列表页面
    back() {
      this.showDetail = false;
    },
    getArea() {
      getArea().then((res) => {
        if (res.success) {
          this.$store.dispatch('setArea', res.data);
        }
      });
    },
  },
  created() {
    // if (this.allOrgList && this.allOrgList.length === 0) {
    //   this.getArea();
    // }
    if (this.$route.query.id) {
      this.checkDetail(this.$route.query.id);
    } else {
      this.query();
    }
    // this.$bus.$on('handleNavMenus', (res) => {
    //   if (res) {
    //     this.showDetail = false;
    //   }
    // });
  },
};
</script>

<style lang="scss" scoped>
.bread-menu-wrap {
  align-items: center;
  font-size: 13px;
  padding: 12px 0 16px;
  border-bottom: 1px dashed #e5e5e5;
  margin-bottom: 20px;
  .cursor {
    cursor: pointer;
  }
  .bold {
    font-weight: bold;
    color: #303133;
    &:hover {
      color: #2c8bff;
    }
  }
}

.top-tab {
  position: relative;
  border-bottom: 2px solid #2c8bff;
  margin-bottom: 20px;
  .tab-contents {
    font-size: 28px;
    font-weight: bold;
    color: #666666;
    .title {
      color: #c9c9c9;
    }
  }
  .search {
    position: absolute;
    right: 10px;
    top: 8px;
    display: flex;
    /deep/.el-input__inner {
      height: 29px;
      border-radius: 15px 0 0 15px;
    }
    .el-button {
      border-radius: 0 15px 15px 0;
    }
  }
  .checkList {
    width: 160px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #414141;
    margin-right: 24px;
    box-sizing: border-box;
    cursor: pointer;
    border: 1px solid #dfdfdf;
    border-bottom: none;
  }
  .current {
    color: #fff;
    background-color: #2c8bff;
    border: none;
  }
}
.query-content {
  padding: 0 24px;
  height: 64px;
  line-height: 64px;
  border-bottom: 1px solid #e7e7e7;
  .article-title {
    font-size: 15px;
    font-weight: bold;
  }
  .article-time {
    font-size: 14px;
  }
}
.page-content {
  margin: auto 20px;
}
</style>
